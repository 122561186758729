<template>
  <div class="app-container electricity-meter">
    <el-row class="filter-line" :gutter="20">
      <el-col :span="4">
        <el-select
          style="width: 100%"
          placeholder="选择项目"
          v-model="projectName"
          @change="projectChange"
        >
          <el-option
            v-for="item in projectOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" class="single-item">
        <el-select
          v-model="form.deviceName"
          multiple
          collapse-tags
          placeholder="选择设备"
          :remote-method="remoteMethod"
          remote
          reserve-keyword
          filterable
          style="width: 100%"
        >
          <el-checkbox
            style="padding-left: 20px; margin: 10px 0"
            v-model="deviceCheckedAll"
            @change="selectAll"
            >全选</el-checkbox
          >
          <el-option
            v-for="item in deviceNameOptions"
            :key="item.productKey + item.deviceName"
            :label="item.deviceName"
            :value="item.deviceName"
          >
            <span style="float: left">{{ item.deviceName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.description
            }}</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="single-item">
        <el-date-picker
          v-model="form.date"
          type="datetimerange"
          value-format="timestamp"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3" class="single-item">
        <el-select
          style="width: 100%"
          placeholder="分析类型"
          v-model="form.type"
          @change="getTableList"
          clearable
        >
          <el-option
            v-for="item in typeOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="getTableList">查询</el-button>
      </el-col>
    </el-row>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:axdlSlot="row">
          <span
            :class="
              isRedSpan(row.datas.axdl, row.datas)
                ? 'red-strong-span'
                : 'normal-span'
            "
          >
            {{ row.datas.axdl }}
          </span>
        </template>
        <template v-slot:deviceNameSlot="row">
          <span class="link-span" @click="jumpToDeviceShadow(row.datas)">
            {{ row.datas.deviceName }}
          </span>
        </template>
        <template v-slot:bxdlSlot="row">
          <span
            :class="
              isRedSpan(row.datas.bxdl, row.datas)
                ? 'red-strong-span'
                : 'normal-span'
            "
          >
            {{ row.datas.bxdl }}
          </span>
        </template>
        <template v-slot:cxdlSlot="row">
          <span
            :class="
              isRedSpan(row.datas.cxdl, row.datas)
                ? 'red-strong-span'
                : 'normal-span'
            "
          >
            {{ row.datas.cxdl }}
          </span>
        </template>
      </finalTable>
    </div>
  </div>
</template>

<script>
import { getThreePhaseList } from "@/api/ruge/bmsNew/electricityMeter.js";
import finalTable from "@/components/FinalTable";
import { findDevicePage } from "@/api/ruge/vlink/device/device";
export default {
  components: {
    finalTable,
  },
  data() {
    return {
      projectOptions: [
        {
          value: "锦绣二期",
          label: "锦绣二期",
        },
        {
          value: "锦绣三期",
          label: "锦绣三期",
        },
      ],
      deviceCheckedAll: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "昨天",
            onClick: (picker) => {
              const yesterday = this.getDay(-1);
              const end = new Date(`${yesterday} 23:59:59`).getTime();
              const start = new Date(`${yesterday} 00:00:00`).getTime();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "今天",
            onClick: (picker) => {
              const today = this.getDay(0);
              const end = new Date(`${today} 23:59:59`).getTime();
              const start = new Date(`${today} 00:00:00`).getTime();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      loadingFlag: false,
      deviceNameOptions: [],
      resourceOptions: [],
      typeOptions: [
        {
          value: "max",
          label: "最大",
        },
        {
          value: "min",
          label: "最小",
        },
        {
          value: "zero",
          label: "为零",
        },
      ],
      projectName: "锦绣二期",
      form: {
        deviceName: [],
        date: "",
        type: "",
      },
      dataset: {
        headerCellStyle: () => {
          return "background-color: #F0F4FA; color: #5D687C; ";
        },
        border: true,
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: false,
        },
        header: [
          { prop: "deviceName", label: "设备", align: "center" },
          { prop: "deviceDescription", label: "设备描述", align: "center" },
          { prop: "axdl", label: "A相电流", align: "center" },
          { prop: "bxdl", label: "B相电流", align: "center" },
          { prop: "cxdl", label: "C相电流", align: "center" },
          { prop: "reportTime", label: "时间", align: "center" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {},
        // 表格内容配置
        detailConfig: {
          deviceName: {
            type: "slot",
            slotName: "deviceNameSlot",
          },
          axdl: {
            type: "slot",
            slotName: "axdlSlot",
          },
          bxdl: {
            type: "slot",
            slotName: "bxdlSlot",
          },
          cxdl: {
            type: "slot",
            slotName: "cxdlSlot",
          },
          reportTime: {
            type: "dateFormat",
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getDeviceList();
  },
  methods: {
    projectChange() {
      this.form.deviceName = [];
      this.getDeviceList();
    },
    selectAll() {
      this.form.deviceName = [];
      if (this.deviceCheckedAll) {
        this.deviceNameOptions.forEach((item) => {
          this.form.deviceName.push(item.deviceName);
        });
      } else {
        this.form.deviceName = [];
      }
      this.$emit("update:updateMultipleSelect", this.form.deviceName);
    },
    jumpToDeviceShadow(row) {
      const url = `${location.origin}${location.pathname}#/iot/device/detail?productKey=irZairXXaj8&deviceName=${row.deviceName}&activeTabName=shadow`;
      window.open(url, "_blank");
    },
    isRedSpan(value, row) {
      if (this.form.type == "max" && value == row.rowMax) {
        return true;
      }
      if (this.form.type == "min" && value == row.rowMin) {
        return true;
      }
      if (this.form.type == "zero" && value == 0) {
        return true;
      }
      return false;
    },
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.getDeviceList(query);
      } else {
        this.deviceNameOptions = this.resourceOptions;
      }
    },
    getDeviceList(filter) {
      findDevicePage({
        ...{
          parentDeviceName: {
            锦绣一期: 60,
            锦绣二期: 15388,
            锦绣三期: 15389,
          }[this.projectName],
          parentDeviceId: {
            锦绣一期: 60,
            锦绣二期: 15388,
            锦绣三期: 15389,
          }[this.projectName],
          productKey: "irZairXXaj8",
          current: 1,
          rowCount: 50,
          enableStatus: "ENABLE",
        },
        ...{
          searchPhrase: filter,
        },
      }).then((res) => {
        if (!this.resourceOptions.length) {
          this.resourceOptions = res.rows;
        }
        this.deviceNameOptions = res.rows;
      });
    },
    getTableList() {
      if (this.form.deviceName.length === 0) {
        this.$message.warning("请至少选择一个设备！");
        return;
      }
      if (!this.form.date || this.form.date.length < 2) {
        this.$message.warning("请选择时间段！");
        return;
      }
      if (!this.form.type) {
        this.$message.warning("请选择分析类型");
        return;
      }
      this.loadingFlag = true;
      getThreePhaseList({
        ...{
          current: this.dataset.pageVO.current,
          rowCount: this.dataset.pageVO.rowCount,
        },
        ...{
          type: this.form.type,
          deviceNames: this.form.deviceName.join(","),
          beginTimestamp: this.form.date[0],
          endTimestamp: this.form.date[1],
          productKey: "irZairXXaj8",
        },
      })
        .then((res) => {
          this.dataset.pageVO.total = res.total;
          this.dataset.tableData = res.rows.map((item) => {
            item.rowMax = Math.max(...[item.axdl, item.bxdl, item.cxdl]);
            item.rowMin = Math.min(...[item.axdl, item.bxdl, item.cxdl]);
            return item;
          });
        })
        .catch(() => {
          this.dataset.pageVO.total = 0;
          this.dataset.tableData = [];
          this.loadingFlag = false;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "paginationChange") {
        this.dataset.pageVO.current = datas.params.current.page;
        this.dataset.pageVO.rowCount = datas.params.current.limit;
        this.getTableList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.electricity-meter {
  .filter-line {
    .single-item {
      display: flex;
      align-items: center;
      .label {
        width: 120px;
        text-align: right;
      }
    }
  }
  .table-container {
    margin-top: 20px;
  }
  .red-strong-span {
    color: #ff0000;
    font-weight: bold;
  }
  .normal-span {
    color: #000;
    font-weight: normal;
  }
  .link-span {
    color: #1a4cec;
    cursor: pointer;
  }
  // ::v-deep(.el-select-dropdown__item) {
  //   padding-right: 20px;
  // }
  ::v-deep(.el-table th.el-table__cell > .cell) {
    font-weight: bold;
  }
}
</style>